<template>
    <header-navigation>
        {{ title }}
        <div class="preview-map-div flex gap-2">
            <router-link
                class="preview-map-btn pill-btn pill-btn-icon"
                :to="{ name: 'map', params: { map: id } }"
                target="_blank"
                >Open Client Shareable Map
            </router-link>
            <router-link
                class="preview-map-btn pill-btn pill-btn-icon"
                :to="{ name: 'host-map', params: { map: id } }"
                target="_blank"
                >Open Host Shareable Map
            </router-link>
        </div>
        <template v-if="!workOrder" #breadcrumbs>
            <div class="breadcrumbs">
                <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
                <router-link v-if="hasUsername" :to="{ name: 'user-maps', params: { user: user } }"
                    >{{ username }} Curated Maps</router-link
                >
                <span>{{ title }}</span>
            </div>
        </template>
    </header-navigation>
</template>

<script>
import { isEmpty, get } from 'utils/lodash';
import { mapGetters, mapActions } from 'vuex';
import HeaderNavigation from 'components/layout/header';
export default {
    components: {
        HeaderNavigation,
    },
    props: {
        user: {
            type: [String, Number],
            default: null,
        },
        workOrder: {
            type: [String, Number],
            default: null,
        },
    },
    computed: {
        ...mapGetters('auth', ['isAdmin', 'isAOM']),
        ...mapGetters('map', ['title', 'id']),
        ...mapGetters('users', { findUser: 'get', hasUser: 'has' }),
        isUserMapsVisible() {
            return this.isAdmin || this.isAOM;
        },
        userMap() {
            return this.findUser(this.user);
        },
        hasUsername() {
            return this.isUserMapsVisible && !isEmpty(this.userMap);
        },
        username() {
            return `${get(this.userMap, 'first_name')}'s`;
        },
    },
    watch: {
        user: {
            immediate: true,
            handler: function () {
                if (!this.workOrder && !this.hasUser(this.user)) {
                    this.fetchUser();
                }
            },
        },
    },
    methods: {
        ...mapActions('users', ['get']),
        async fetchUser() {
            await this.get(this.user);
        },
    },
};
</script>
